import { get, post } from "@infrastructure/api/apiCall"
import { type FormSubmit, formSubmitSchema } from "./registration/FormSubmit"
import { yup } from "utils/translated-yup"
import { type AllRacesFilter } from "./all-races/allRacesData"
import { type SendInfoRequest, sendInfoRequestSchema } from "@website/racer/send-info/SendInfo"
import { type UpdateByGuidFormSubmit, updateByGuidformSubmitSchema } from "./registration/UpdateByGuidFormSubmit"
import { type RaceAndSubraceSlugs, type SeriesSlug, type SubRaceSlug } from "common"
import { type RaceSlugWithRefGuid, raceSlugWithRefGuidSchema } from "./race/RaceSlugWithRefGuid"

export const webApiBaseUrl = "/webapi"

export const sitemapUrl = "/sitemap.xml"

export default {
  status: get(webApiBaseUrl + "/status"),
  home: {
    allRaces: get(webApiBaseUrl + "/home/allraces")
  },
  race: {
    detail: get<RaceSlugWithRefGuid>(webApiBaseUrl + "/race/detail", raceSlugWithRefGuidSchema),
    startNumber: get<{ guid: string }>(webApiBaseUrl + "/race/start_number", yup.object<{ guid: string }>().shape({ guid: yup.string().required() }))
  },
  series: {
    detail: get<SeriesSlug>(webApiBaseUrl + "/series/detail", yup.object<{ slug: string }>().shape({ slug: yup.string().required() }))
  },
  allRaces: {
    filtered: get(webApiBaseUrl + "/allraces/filtered", yup.object<AllRacesFilter>().shape({ archived: yup.boolean().required(), page: yup.number().required() }))
  },
  startlist: {
    detail: get<RaceAndSubraceSlugs>(webApiBaseUrl + "/startlist/detail", yup.object<RaceAndSubraceSlugs>().shape({ raceSlug: yup.string().required(), subraceSlug: yup.string().required() }))
  },
  results: {
    detail: get<RaceAndSubraceSlugs>(webApiBaseUrl + "/results/detail", yup.object<RaceAndSubraceSlugs>().shape({ raceSlug: yup.string().required(), subraceSlug: yup.string().required() }))
  },
  racer: {
    info: get<{ guid: string; token?: string }>(webApiBaseUrl + "/racer/info", yup.object<{ guid: string; token?: string }>().shape({ guid: yup.string().required(), token: yup.string() })),
    sendInfoMail: post<SendInfoRequest>(webApiBaseUrl + "/racer/send_info_mail", sendInfoRequestSchema),
    sendInfoRacer: post<{ guid: string }>(webApiBaseUrl + "/racer/send_info_racer", yup.object<{ guid: string }>().shape({ guid: yup.string().required() }))
  },
  registration: {
    formDetail: get<RaceAndSubraceSlugs>(
      webApiBaseUrl + "/registration/form_detail",
      yup.object<RaceAndSubraceSlugs>().shape({ raceSlug: yup.string().required(), subraceSlug: yup.string().required() })
    ),
    onplaceFormDetail: get<SubRaceSlug>(webApiBaseUrl + "/registration/onplace_form_detail", yup.object<SubRaceSlug>().shape({ slug: yup.string().required() })),
    submitForm: post<FormSubmit>(webApiBaseUrl + "/registration/submit_form", formSubmitSchema),
    confirmPage: get<{ guid: string }>(webApiBaseUrl + "/registration/confirm_page", yup.object<{ guid: string }>().shape({ guid: yup.string().required() })),
    referralsPage: get<{ guid: string }>(webApiBaseUrl + "/registration/referrals_page", yup.object<{ guid: string }>().shape({ guid: yup.string().required() })),
    updateFormValues: get<{ guid: string; token: string }>(
      webApiBaseUrl + "/registration/update_form_values",
      yup.object<{ guid: string; token: string }>().shape({ guid: yup.string().required(), token: yup.string().required() })
    ),
    submitUpdatedForm: post<UpdateByGuidFormSubmit>(webApiBaseUrl + "/registration/submit_updated_form", updateByGuidformSubmitSchema),
    getCoupon: post<{ subraceId: number; code: string }>(
      webApiBaseUrl + "/registration/get_coupon",
      yup.object<{ subraceId: number; code: string }>().shape({ subraceId: yup.number().required(), code: yup.string().required() })
    )
  },
  checkout: {
    paymentPage: get<{ guid: string }>(webApiBaseUrl + "/checkout/payment_page", yup.object<{ guid: string }>().shape({ guid: yup.string().required() })),
    stripeCheckoutRedirect: post<{ guid: string }>(webApiBaseUrl + "/checkout/stripe_checkout_redirect", yup.object<{ guid: string }>().shape({ guid: yup.string().required() })),
    stripeCheckoutSuccess: get<{ guid: string; sessionId: string }>(
      webApiBaseUrl + "/checkout/stripe_checkout_success",
      yup.object<{ guid: string; sessionId: string }>().shape({ guid: yup.string().required(), sessionId: yup.string().required() })
    )
  }
}
